// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Feed from '../components/Feed';
import Page from '../components/Page';

import { Menu, Button } from 'semantic-ui-react'

import { useSiteMetadata } from '../hooks';
import type { PageContext, AllMarkdownRemark } from '../types';

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext
};

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}

const IndexTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const {
    currentPage
  } = pageContext;

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return null;
  }

  type Props = {
    isIndex?: boolean,
  };

  const spanCSS = {
    color: 'white',
    verticalAlign: 'middle',
    paddingLeft: '10px'
  };

  const imgCSS = {
    float: 'left'
  };

  const h1CSS = {
    marginTop: '5px',
    marginBottom: '0px'
  };

  const linkCSS = {
    color: 'white',
    fontWeight: 'bold'
  };

  const linkSpan = {
    marginLeft: '-40px'
  };

  const h2Style = {
    color: 'white',
    marginTop: '0px',
    paddingLeft: '0px',
    paddingRight: '10px'
  };

  const { edges } = data.allMarkdownRemark;
  const pageTitle = currentPage > 0 ? `Posts - Page ${currentPage} - ${siteTitle}` : siteTitle;

  return (
    <Layout title={pageTitle} description={siteSubtitle} style={{backgroundColor: 'green'}}>
      <Page>
        <header style={{marginBottom: '5px'}}>
          <Menu secondary>
            <img
              src={'/photo.jpg'}
              width="45"
              height="45"
            />
            <Menu.Item header href='/'>
              Smoke-Indica.com
            </Menu.Item>
          </Menu>
          <Button.Group compact style={{marginTop: '5px'}}>
            <Button href='/' icon='home' />
            <Button href='/pages/about'>About</Button>
            <Button href='/pages/related'>Related</Button>
            <Button href='/pages/stats'>Stats</Button>
            <Button href='/pages/explorers'>Explorers</Button>
          </Button.Group>
        </header>
        <Feed edges={edges} />
        <br/>
        <h3 style={{ textAlign: 'center', margin: '0px' }}>👋 Scroll within the box above 👆</h3>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!) {
    allMarkdownRemark(limit: $postsLimit, skip: $postsOffset, filter: {frontmatter: {template: {eq: "post"}, draft: {ne: true}}}, sort: {order: DESC, fields: [frontmatter___date, frontmatter___net_votes, frontmatter___reputation]}) {
      edges {
        node {
          fields {
            slug
            author
            net_votes
            total_payout_value
            pending_payout_value
            images
          }
          frontmatter {
            title
            date
            sortvalue
            category
            tags
          }
        }
      }
    }
  }
`;

export default IndexTemplate;
